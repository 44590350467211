// Here you can add other styles
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100&display=swap');


.centerBody {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f1f1f1;
}

.headerBorder {
    box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    border-bottom: 5px solid;
}

.footer {
    background-color: rgba(43, 61, 76, 0.7);
    border-top: none;
}


.pgb .step {
    text-align: center;
    position: relative;
}

.pgb h2 {
    font-size: 1.3rem;
}

.pgb .step p {
    position: absolute;
    height: 60px;
    width: 100%;
    text-align: center;
    display: block;
    z-index: 3;
    color: #fff;
    font-size: 160%;
    line-height: 55px;
    opacity: .7;
}

.pgb .active.step p {
    opacity: 1;
    font-weight: 600;
}

.pgb .img-circle {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #9E9E9E;
    border: 4px solid #fff;
}

.pgb .img-circle>.img-circle-text {
    margin: auto;
}

.pgb .complete .img-circle {
    background-color: #4CAF50;
}

.pgb .active .img-circle {
    background-color: #FF9800;
}

.pgb .step .img-circle:before {
    content: "";
    display: block;
    background: #9E9E9E;
    height: 4px;
    width: 50%;
    position: absolute;
    bottom: 50%;
    left: 0;
    z-index: -1;
    margin-right: 24px;
}

.pgb .step .img-circle:after {
    content: "";
    display: block;
    background: #9E9E9E;
    height: 4px;
    width: 50%;
    position: absolute;
    bottom: 50%;
    left: 50%;
    z-index: -1;
}

.pgb .step.active .img-circle:after {
    background: #9E9E9E;
}

.pgb .step.complete .img-circle:after,
.pgb .step.active .img-circle:before {
    background: #4CAF50;
}

.pgb .step:last-of-type .img-circle:after,
.pgb .step:first-of-type .img-circle:before {
    display: none;
}


.pgb .img-circle-page {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #9E9E9E;
    border: 4px solid #fff;
}

.pgb .complete .img-circle-page {
    background-color: #4CAF50;
}

.pgb .active .img-circle-page {
    background-color: #FF9800;
}

.opacity-effect {
    transition: opacity 0.6s;
}

.front-screen-item {
    position: fixed;
    top: 50%;
    left: 50%;
    z-index: 10200;
}

.disable-front-screen {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10100;
    opacity: 0.5;
    background-color: #000;
    height: 100vh;
    width: 100vw;
}

.paymentSelected {
    background: #F2F2F2;
    cursor: pointer;
    border: 2px solid;
    border-color: #070707;
    border-radius: 5px;
}

.unSelected {
    cursor: pointer;
}

.centerPaymentIcons {
    justify-content: center;
    display: flex;
    align-items: center;
}

.imgPaymentMethos {
    height: 70%;
}

.centerText {
    margin: 1% 1%;
    text-align: center;
}

/* .onSelectHeli {
    transform: translate(40%, 0);
    -webkit-transform: translate(40%, 0);
    -o-transform: translate(40%, 0);
    -moz-transform: translate(40%, 0);

} */

.onSelectMacro {
    transform: translate(-20%, 0);
    -webkit-transform: translate(-20%, 0);
    -o-transform: translate(-20%, 0);
    -moz-transform: translate(-20%, 0);
}

.object {

    transition: all 1s ease-in-out;
    -webkit-transition: all 1s ease-in-out;
    /** Chrome & Safari **/
    -moz-transition: all 1s ease-in-out;
    /** Firefox **/
    -o-transition: all 1s ease-in-out;
    /** Opera **/
}
.list-unstyled li {
  text-align: justify;
}

/* START CARD */
.roundedCard {
    border-radius: 15px;
}

.cardText {
    font-size: 1rem !important;
}

.colorBlack {
    color: #000;
}
.modal-content{
    border-radius: 15px;
}

/* END CARD */

/* START CALENDAR */


//padre del evento
.rbc-event {
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

}

.rbc-event-content {
  &:hover {
    opacity:1,
  }
}


//label de horarios del calendar
.rbc-event-label {
  display: none;


}
//Horario izquiero del calendar
.rbc-label {
  display: none;
}

.rbc-row-segment{
    width: 58px;
    height: 58px;
  }

//   Para pantallas menor a 768px (tablets), reduce el tamaño a 50px
  
  @media (max-width: 768px) {
    .rbc-row-segment {
      width: 50px;
      height: 50px;
    }
  }
//   Para pantallas menor a 576px (móviles), lo reduce aún más a 45px
  @media (max-width: 576px) {
    .rbc-row-segment {
      width: 45px;
      height: 45px;
    }
  }


/* END CALENDAR */
/* START HEADER */
.headerContainer {
    display: flex;
    height: 90px;
    background-color: rgba(43, 61, 76, 0.7);
    box-shadow: 1px 2px 2px 5px rgba(89, 89, 89, 0.5);
    z-index: 10;
}

.logoContainer {
    margin-left: 2%;
    width: 20%;
}

.textHeaderContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 50%;
    flex-direction: column;
}

.textTopContainer {
    display: flex;
}

.interFont {
    font-family: 'Inter' !important;
    font-style: normal;
    font-weight: 900;
    font-size: 1.1rem;
    color: gray !important;
}
.h1-header{
    font-size: 1.6rem;
    color: white;
    margin-left: 15.1%;
    width: max-content;
}

.noMargin {
    margin: 0;
}



.marginTextRight {
    margin-right: 1%;
}

@media (max-width: 1200px) {
    .textHeaderContainer {
        display: none
    }

    .logoContainer {
        width: 100%;
        text-align: center;
    }
    .logoContainer {
    margin-left: 0;

}
}

/* END HEADER */

.boxShadow{
  box-shadow: 0px 2px 10px 5px rgb(89 89 89 / 50%) !important;
}


/*START recaptcha */
.captcha-container {
	& > div {
		display: flex;
		justify-content: center;
	}
}
/* END recaptcha */
.text-download-button{
    color: white !important;
}

.text-footer__download__text{
    color: white !important;
    text-decoration: none;
    cursor: pointer;
}
@media (max-width: 450px) {
    .logoContainer {
    margin-left: 0;
    margin-right: 13px;

}
}

.desktop-image {
  max-width: 300px; /* Ajustar al ancho máximo de su contenedor */
  max-height: 500px;
  margin-bottom: 20px;
  border-radius: 30px;
}

@media (min-width: 768px) {
  .desktop-image {
    max-width: 600px; /* Ajustar al ancho máximo de su contenedor */
    height: auto; /* Mantener la proporción */
  }
}
